<template>
  <v-row justify="center" align="center">
    <v-col class="text-center">
      <h1 class="display-2 primary--text">Whoops, 404</h1>
      <p>The page you were looking for does not exist</p>
      <v-btn to="/" outlined color="primary"> Get me out of here! </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo: {
    title: "Shakti Tree Map",
    titleTemplate: "%s | Page Not Found",
  },
};
</script>
